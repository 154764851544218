(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:neoPrintCardTemplate
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="components">
   <file name="index.html">
   <neo-print-team-template></neo-print-team-template>
   </file>
   </example>
   *
   */
  angular
      .module('neo')
      .directive('neoPrintTeamTemplate', neoPrintTeamTemplate);

  function neoPrintTeamTemplate() {
    return {
      restrict: 'E',
      scope: {
        team: '='
      },
      templateUrl: 'directives/neo-print-team-template/neo-print-team-template-directive.tpl.html',
      replace: false,
      controllerAs: 'vm',
      controller: function ($scope) {
        var vm = this;

        vm.headers = ['LICENSE_CODE', 'NAME', 'SURNAMES', 'NIF/NIE', 'LICENSE_TYPE', 'CATEGORY', 'BIRTH_DATE'];
        vm.team = $scope.team;
        vm.currentDate = moment(new Date()).format('L')
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
